import Vue from 'vue'
import axios from '@/libs/axios'

export default {
  name: 'ClassifiersComponent',
  data: {
    classifiers: [],
  },
  install() {
    Vue.prototype.$classifiers = () => this
  },
  async fetchClassifiers(callback) {
    return axios.get('/v1/classifiers')
      .then(classifiers => {
        this.data.classifiers = classifiers.data
        if (callback) {
          callback()
        }
      })
  },
  getClassifier(classifier) {
    return this.data?.classifiers?.[classifier] || {}
  },
  classifierContains(classifier, value) {
    if (this.data.classifiers) {
      if (this.data.classifiers[classifier]) {
        return this.data.classifiers[classifier].includes(value)
      }
    }

    return false
  },
  getOptions(classifier, addSelect = true) {
    const classifiers = this.getClassifier(classifier)
    const options = []
    if (addSelect) {
      options.push({ value: '', text: '----select----' })
    }
    Object.entries(classifiers).forEach(([id, val]) => {
      options.push({ value: id, text: val })
    })

    return options
  },
}
